;( function(){
	var qr_class = window.LyteQr || $L.qr.class_instance,
	kanji_encoding_table = [
		"81 3f    　 、 。 ， ． ・ ： ； ？ ！ ゛ ゜ ´ ｀ ¨",
		"81 4f ＾ ￣ ＿ ヽ ヾ ゝ ゞ 〃 仝 々 〆 〇 ー ― ‐ ／",
		"81 5f ＼ ～ ∥ ｜ … ‥ ‘ ’ “ ” （ ） 〔 〕 ［ ］",
		"81 6f ｛ ｝ 〈 〉 《 》 「 」 『 』 【 】 ＋ － ± ×",
		"81 80 ÷ ＝ ≠ ＜ ＞ ≦ ≧ ∞ ∴ ♂ ♀ ° ′ ″ ℃ ￥",
		"81 90 ＄ ￠ ￡ ％ ＃ ＆ ＊ ＠ § ☆ ★ ○ ● ◎ ◇",
		"81 9e    ◆ □ ■ △ ▲ ▽ ▼ ※ 〒 → ← ↑ ↓ 〓",
		"81 ae                               ∈ ∋ ⊆ ⊇ ⊂ ⊃",
		"81 be ∪ ∩                         ∧ ∨ ￢ ⇒ ⇔ ∀",
		"81 ce ∃                                  ∠ ⊥ ⌒ ∂",
		"81 de ∇ ≡ ≒ ≪ ≫ √ ∽ ∝ ∵ ∫ ∬",
		"81 ee       Å ‰ ♯ ♭ ♪ † ‡ ¶             ◯",
		"82 3f",
		"82 4f ０ １ ２ ３ ４ ５ ６ ７ ８ ９",
		"82 5f    Ａ Ｂ Ｃ Ｄ Ｅ Ｆ Ｇ Ｈ Ｉ Ｊ Ｋ Ｌ Ｍ Ｎ Ｏ",
		"82 6f Ｐ Ｑ Ｒ Ｓ Ｔ Ｕ Ｖ Ｗ Ｘ Ｙ Ｚ",
		"82 80    ａ ｂ ｃ ｄ ｅ ｆ ｇ ｈ ｉ ｊ ｋ ｌ ｍ ｎ ｏ",
		"82 90 ｐ ｑ ｒ ｓ ｔ ｕ ｖ ｗ ｘ ｙ ｚ",
		"82 9e    ぁ あ ぃ い ぅ う ぇ え ぉ お か が き ぎ く",
		"82 ae ぐ け げ こ ご さ ざ し じ す ず せ ぜ そ ぞ た",
		"82 be だ ち ぢ っ つ づ て で と ど な に ぬ ね の は",
		"82 ce ば ぱ ひ び ぴ ふ ぶ ぷ へ べ ぺ ほ ぼ ぽ ま み",
		"82 de む め も ゃ や ゅ ゆ ょ よ ら り る れ ろ ゎ わ",
		"82 ee ゐ ゑ を ん",
		"83 3f    ァ ア ィ イ ゥ ウ ェ エ ォ オ カ ガ キ ギ ク",
		"83 4f グ ケ ゲ コ ゴ サ ザ シ ジ ス ズ セ ゼ ソ ゾ タ",
		"83 5f ダ チ ヂ ッ ツ ヅ テ デ ト ド ナ ニ ヌ ネ ノ ハ",
		"83 6f バ パ ヒ ビ ピ フ ブ プ ヘ ベ ペ ホ ボ ポ マ ミ",
		"83 80 ム メ モ ャ ヤ ュ ユ ョ ヨ ラ リ ル レ ロ ヮ ワ",
		"83 90 ヰ ヱ ヲ ン ヴ ヵ ヶ",
		"83 9e    Α Β Γ Δ Ε Ζ Η Θ Ι Κ Λ Μ Ν Ξ Ο",
		"83 ae Π Ρ Σ Τ Υ Φ Χ Ψ Ω",
		"83 be    α β γ δ ε ζ η θ ι κ λ μ ν ξ ο",
		"83 ce π ρ σ τ υ φ χ ψ ω",
		"83 de",
		"83 ee",
		"84 3f    А Б В Г Д Е Ё Ж З И Й К Л М Н",
		"84 4f О П Р С Т У Ф Х Ц Ч Ш Щ Ъ Ы Ь Э",
		"84 5f Ю Я",
		"84 6f    а б в г д е ё ж з и й к л м н",
		"84 80 о п р с т у ф х ц ч ш щ ъ ы ь э",
		"84 90 ю я",
		"84 9e    ─ │ ┌ ┐ ┘ └ ├ ┬ ┤ ┴ ┼ ━ ┃ ┏ ┓",
		"84 ae ┛ ┗ ┣ ┳ ┫ ┻ ╋ ┠ ┯ ┨ ┷ ┿ ┝ ┰ ┥ ┸",
		"84 be ╂",
		"87 3f    ① ② ③ ④ ⑤ ⑥ ⑦ ⑧ ⑨ ⑩ ⑪ ⑫ ⑬ ⑭ ⑮",
		"87 4f ⑯ ⑰ ⑱ ⑲ ⑳ Ⅰ Ⅱ Ⅲ Ⅳ Ⅴ Ⅵ Ⅶ Ⅷ Ⅸ Ⅹ",
		"87 5f ㍉ ㌔ ㌢ ㍍ ㌘ ㌧ ㌃ ㌶ ㍑ ㍗ ㌍ ㌦ ㌣ ㌫ ㍊ ㌻",
		"87 6f ㎜ ㎝ ㎞ ㎎ ㎏ ㏄ ㎡                         ㍻",
		"87 80 〝 〟 № ㏍ ℡ ㊤ ㊥ ㊦ ㊧ ㊨ ㈱ ㈲ ㈹ ㍾ ㍽ ㍼",
		"87 90 ≒ ≡ ∫ ∮ ∑ √ ⊥ ∠ ∟ ⊿ ∵ ∩ ∪",
		"88 9e    亜 唖 娃 阿 哀 愛 挨 姶 逢 葵 茜 穐 悪 握 渥",
		"88 ae 旭 葦 芦 鯵 梓 圧 斡 扱 宛 姐 虻 飴 絢 綾 鮎 或",
		"88 be 粟 袷 安 庵 按 暗 案 闇 鞍 杏 以 伊 位 依 偉 囲",
		"88 ce 夷 委 威 尉 惟 意 慰 易 椅 為 畏 異 移 維 緯 胃",
		"88 de 萎 衣 謂 違 遺 医 井 亥 域 育 郁 磯 一 壱 溢 逸",
		"88 ee 稲 茨 芋 鰯 允 印 咽 員 因 姻 引 飲 淫 胤 蔭",
		"89 3f    院 陰 隠 韻 吋 右 宇 烏 羽 迂 雨 卯 鵜 窺 丑",
		"89 4f 碓 臼 渦 嘘 唄 欝 蔚 鰻 姥 厩 浦 瓜 閏 噂 云 運",
		"89 5f 雲 荏 餌 叡 営 嬰 影 映 曳 栄 永 泳 洩 瑛 盈 穎",
		"89 6f 頴 英 衛 詠 鋭 液 疫 益 駅 悦 謁 越 閲 榎 厭 円",
		"89 80 園 堰 奄 宴 延 怨 掩 援 沿 演 炎 焔 煙 燕 猿 縁",
		"89 90 艶 苑 薗 遠 鉛 鴛 塩 於 汚 甥 凹 央 奥 往 応",
		"89 9e    押 旺 横 欧 殴 王 翁 襖 鴬 鴎 黄 岡 沖 荻 億",
		"89 ae 屋 憶 臆 桶 牡 乙 俺 卸 恩 温 穏 音 下 化 仮 何",
		"89 be 伽 価 佳 加 可 嘉 夏 嫁 家 寡 科 暇 果 架 歌 河",
		"89 ce 火 珂 禍 禾 稼 箇 花 苛 茄 荷 華 菓 蝦 課 嘩 貨",
		"89 de 迦 過 霞 蚊 俄 峨 我 牙 画 臥 芽 蛾 賀 雅 餓 駕",
		"89 ee 介 会 解 回 塊 壊 廻 快 怪 悔 恢 懐 戒 拐 改",
		"8a 3f    魁 晦 械 海 灰 界 皆 絵 芥 蟹 開 階 貝 凱 劾",
		"8a 4f 外 咳 害 崖 慨 概 涯 碍 蓋 街 該 鎧 骸 浬 馨 蛙",
		"8a 5f 垣 柿 蛎 鈎 劃 嚇 各 廓 拡 撹 格 核 殻 獲 確 穫",
		"8a 6f 覚 角 赫 較 郭 閣 隔 革 学 岳 楽 額 顎 掛 笠 樫",
		"8a 80 橿 梶 鰍 潟 割 喝 恰 括 活 渇 滑 葛 褐 轄 且 鰹",
		"8a 90 叶 椛 樺 鞄 株 兜 竃 蒲 釜 鎌 噛 鴨 栢 茅 萱",
		"8a 9e    粥 刈 苅 瓦 乾 侃 冠 寒 刊 勘 勧 巻 喚 堪 姦",
		"8a ae 完 官 寛 干 幹 患 感 慣 憾 換 敢 柑 桓 棺 款 歓",
		"8a be 汗 漢 澗 潅 環 甘 監 看 竿 管 簡 緩 缶 翰 肝 艦",
		"8a ce 莞 観 諌 貫 還 鑑 間 閑 関 陥 韓 館 舘 丸 含 岸",
		"8a de 巌 玩 癌 眼 岩 翫 贋 雁 頑 顔 願 企 伎 危 喜 器",
		"8a ee 基 奇 嬉 寄 岐 希 幾 忌 揮 机 旗 既 期 棋 棄",
		"8b 3f    機 帰 毅 気 汽 畿 祈 季 稀 紀 徽 規 記 貴 起",
		"8b 4f 軌 輝 飢 騎 鬼 亀 偽 儀 妓 宜 戯 技 擬 欺 犠 疑",
		"8b 5f 祇 義 蟻 誼 議 掬 菊 鞠 吉 吃 喫 桔 橘 詰 砧 杵",
		"8b 6f 黍 却 客 脚 虐 逆 丘 久 仇 休 及 吸 宮 弓 急 救",
		"8b 80 朽 求 汲 泣 灸 球 究 窮 笈 級 糾 給 旧 牛 去 居",
		"8b 90 巨 拒 拠 挙 渠 虚 許 距 鋸 漁 禦 魚 亨 享 京",
		"8b 9e    供 侠 僑 兇 競 共 凶 協 匡 卿 叫 喬 境 峡 強",
		"8b ae 彊 怯 恐 恭 挟 教 橋 況 狂 狭 矯 胸 脅 興 蕎 郷",
		"8b be 鏡 響 饗 驚 仰 凝 尭 暁 業 局 曲 極 玉 桐 粁 僅",
		"8b ce 勤 均 巾 錦 斤 欣 欽 琴 禁 禽 筋 緊 芹 菌 衿 襟",
		"8b de 謹 近 金 吟 銀 九 倶 句 区 狗 玖 矩 苦 躯 駆 駈",
		"8b ee 駒 具 愚 虞 喰 空 偶 寓 遇 隅 串 櫛 釧 屑 屈",
		"8c 3f    掘 窟 沓 靴 轡 窪 熊 隈 粂 栗 繰 桑 鍬 勲 君",
		"8c 4f 薫 訓 群 軍 郡 卦 袈 祁 係 傾 刑 兄 啓 圭 珪 型",
		"8c 5f 契 形 径 恵 慶 慧 憩 掲 携 敬 景 桂 渓 畦 稽 系",
		"8c 6f 経 継 繋 罫 茎 荊 蛍 計 詣 警 軽 頚 鶏 芸 迎 鯨",
		"8c 80 劇 戟 撃 激 隙 桁 傑 欠 決 潔 穴 結 血 訣 月 件",
		"8c 90 倹 倦 健 兼 券 剣 喧 圏 堅 嫌 建 憲 懸 拳 捲",
		"8c 9e    検 権 牽 犬 献 研 硯 絹 県 肩 見 謙 賢 軒 遣",
		"8c ae 鍵 険 顕 験 鹸 元 原 厳 幻 弦 減 源 玄 現 絃 舷",
		"8c be 言 諺 限 乎 個 古 呼 固 姑 孤 己 庫 弧 戸 故 枯",
		"8c ce 湖 狐 糊 袴 股 胡 菰 虎 誇 跨 鈷 雇 顧 鼓 五 互",
		"8c de 伍 午 呉 吾 娯 後 御 悟 梧 檎 瑚 碁 語 誤 護 醐",
		"8c ee 乞 鯉 交 佼 侯 候 倖 光 公 功 効 勾 厚 口 向",
		"8d 3f    后 喉 坑 垢 好 孔 孝 宏 工 巧 巷 幸 広 庚 康",
		"8d 4f 弘 恒 慌 抗 拘 控 攻 昂 晃 更 杭 校 梗 構 江 洪",
		"8d 5f 浩 港 溝 甲 皇 硬 稿 糠 紅 紘 絞 綱 耕 考 肯 肱",
		"8d 6f 腔 膏 航 荒 行 衡 講 貢 購 郊 酵 鉱 砿 鋼 閤 降",
		"8d 80 項 香 高 鴻 剛 劫 号 合 壕 拷 濠 豪 轟 麹 克 刻",
		"8d 90 告 国 穀 酷 鵠 黒 獄 漉 腰 甑 忽 惚 骨 狛 込",
		"8d 9e    此 頃 今 困 坤 墾 婚 恨 懇 昏 昆 根 梱 混 痕",
		"8d ae 紺 艮 魂 些 佐 叉 唆 嵯 左 差 査 沙 瑳 砂 詐 鎖",
		"8d be 裟 坐 座 挫 債 催 再 最 哉 塞 妻 宰 彩 才 採 栽",
		"8d ce 歳 済 災 采 犀 砕 砦 祭 斎 細 菜 裁 載 際 剤 在",
		"8d de 材 罪 財 冴 坂 阪 堺 榊 肴 咲 崎 埼 碕 鷺 作 削",
		"8d ee 咋 搾 昨 朔 柵 窄 策 索 錯 桜 鮭 笹 匙 冊 刷",
		"8e 3f    察 拶 撮 擦 札 殺 薩 雑 皐 鯖 捌 錆 鮫 皿 晒",
		"8e 4f 三 傘 参 山 惨 撒 散 桟 燦 珊 産 算 纂 蚕 讃 賛",
		"8e 5f 酸 餐 斬 暫 残 仕 仔 伺 使 刺 司 史 嗣 四 士 始",
		"8e 6f 姉 姿 子 屍 市 師 志 思 指 支 孜 斯 施 旨 枝 止",
		"8e 80 死 氏 獅 祉 私 糸 紙 紫 肢 脂 至 視 詞 詩 試 誌",
		"8e 90 諮 資 賜 雌 飼 歯 事 似 侍 児 字 寺 慈 持 時",
		"8e 9e    次 滋 治 爾 璽 痔 磁 示 而 耳 自 蒔 辞 汐 鹿",
		"8e ae 式 識 鴫 竺 軸 宍 雫 七 叱 執 失 嫉 室 悉 湿 漆",
		"8e be 疾 質 実 蔀 篠 偲 柴 芝 屡 蕊 縞 舎 写 射 捨 赦",
		"8e ce 斜 煮 社 紗 者 謝 車 遮 蛇 邪 借 勺 尺 杓 灼 爵",
		"8e de 酌 釈 錫 若 寂 弱 惹 主 取 守 手 朱 殊 狩 珠 種",
		"8e ee 腫 趣 酒 首 儒 受 呪 寿 授 樹 綬 需 囚 収 周",
		"8f 3f    宗 就 州 修 愁 拾 洲 秀 秋 終 繍 習 臭 舟 蒐",
		"8f 4f 衆 襲 讐 蹴 輯 週 酋 酬 集 醜 什 住 充 十 従 戎",
		"8f 5f 柔 汁 渋 獣 縦 重 銃 叔 夙 宿 淑 祝 縮 粛 塾 熟",
		"8f 6f 出 術 述 俊 峻 春 瞬 竣 舜 駿 准 循 旬 楯 殉 淳",
		"8f 80 準 潤 盾 純 巡 遵 醇 順 処 初 所 暑 曙 渚 庶 緒",
		"8f 90 署 書 薯 藷 諸 助 叙 女 序 徐 恕 鋤 除 傷 償",
		"8f 9e    勝 匠 升 召 哨 商 唱 嘗 奨 妾 娼 宵 将 小 少",
		"8f ae 尚 庄 床 廠 彰 承 抄 招 掌 捷 昇 昌 昭 晶 松 梢",
		"8f be 樟 樵 沼 消 渉 湘 焼 焦 照 症 省 硝 礁 祥 称 章",
		"8f ce 笑 粧 紹 肖 菖 蒋 蕉 衝 裳 訟 証 詔 詳 象 賞 醤",
		"8f de 鉦 鍾 鐘 障 鞘 上 丈 丞 乗 冗 剰 城 場 壌 嬢 常",
		"8f ee 情 擾 条 杖 浄 状 畳 穣 蒸 譲 醸 錠 嘱 埴 飾",
		"90 3f    拭 植 殖 燭 織 職 色 触 食 蝕 辱 尻 伸 信 侵",
		"90 4f 唇 娠 寝 審 心 慎 振 新 晋 森 榛 浸 深 申 疹 真",
		"90 5f 神 秦 紳 臣 芯 薪 親 診 身 辛 進 針 震 人 仁 刃",
		"90 6f 塵 壬 尋 甚 尽 腎 訊 迅 陣 靭 笥 諏 須 酢 図 厨",
		"90 80 逗 吹 垂 帥 推 水 炊 睡 粋 翠 衰 遂 酔 錐 錘 随",
		"90 90 瑞 髄 崇 嵩 数 枢 趨 雛 据 杉 椙 菅 頗 雀 裾",
		"90 9e    澄 摺 寸 世 瀬 畝 是 凄 制 勢 姓 征 性 成 政",
		"90 ae 整 星 晴 棲 栖 正 清 牲 生 盛 精 聖 声 製 西 誠",
		"90 be 誓 請 逝 醒 青 静 斉 税 脆 隻 席 惜 戚 斥 昔 析",
		"90 ce 石 積 籍 績 脊 責 赤 跡 蹟 碩 切 拙 接 摂 折 設",
		"90 de 窃 節 説 雪 絶 舌 蝉 仙 先 千 占 宣 専 尖 川 戦",
		"90 ee 扇 撰 栓 栴 泉 浅 洗 染 潜 煎 煽 旋 穿 箭 線",
		"91 3f    繊 羨 腺 舛 船 薦 詮 賎 践 選 遷 銭 銑 閃 鮮",
		"91 4f 前 善 漸 然 全 禅 繕 膳 糎 噌 塑 岨 措 曾 曽 楚",
		"91 5f 狙 疏 疎 礎 祖 租 粗 素 組 蘇 訴 阻 遡 鼠 僧 創",
		"91 6f 双 叢 倉 喪 壮 奏 爽 宋 層 匝 惣 想 捜 掃 挿 掻",
		"91 80 操 早 曹 巣 槍 槽 漕 燥 争 痩 相 窓 糟 総 綜 聡",
		"91 90 草 荘 葬 蒼 藻 装 走 送 遭 鎗 霜 騒 像 増 憎",
		"91 9e    臓 蔵 贈 造 促 側 則 即 息 捉 束 測 足 速 俗",
		"91 ae 属 賊 族 続 卒 袖 其 揃 存 孫 尊 損 村 遜 他 多",
		"91 be 太 汰 詑 唾 堕 妥 惰 打 柁 舵 楕 陀 駄 騨 体 堆",
		"91 ce 対 耐 岱 帯 待 怠 態 戴 替 泰 滞 胎 腿 苔 袋 貸",
		"91 de 退 逮 隊 黛 鯛 代 台 大 第 醍 題 鷹 滝 瀧 卓 啄",
		"91 ee 宅 托 択 拓 沢 濯 琢 託 鐸 濁 諾 茸 凧 蛸 只",
		"92 3f    叩 但 達 辰 奪 脱 巽 竪 辿 棚 谷 狸 鱈 樽 誰",
		"92 4f 丹 単 嘆 坦 担 探 旦 歎 淡 湛 炭 短 端 箪 綻 耽",
		"92 5f 胆 蛋 誕 鍛 団 壇 弾 断 暖 檀 段 男 談 値 知 地",
		"92 6f 弛 恥 智 池 痴 稚 置 致 蜘 遅 馳 築 畜 竹 筑 蓄",
		"92 80 逐 秩 窒 茶 嫡 着 中 仲 宙 忠 抽 昼 柱 注 虫 衷",
		"92 90 註 酎 鋳 駐 樗 瀦 猪 苧 著 貯 丁 兆 凋 喋 寵",
		"92 9e    帖 帳 庁 弔 張 彫 徴 懲 挑 暢 朝 潮 牒 町 眺",
		"92 ae 聴 脹 腸 蝶 調 諜 超 跳 銚 長 頂 鳥 勅 捗 直 朕",
		"92 be 沈 珍 賃 鎮 陳 津 墜 椎 槌 追 鎚 痛 通 塚 栂 掴",
		"92 ce 槻 佃 漬 柘 辻 蔦 綴 鍔 椿 潰 坪 壷 嬬 紬 爪 吊",
		"92 de 釣 鶴 亭 低 停 偵 剃 貞 呈 堤 定 帝 底 庭 廷 弟",
		"92 ee 悌 抵 挺 提 梯 汀 碇 禎 程 締 艇 訂 諦 蹄 逓",
		"93 3f    邸 鄭 釘 鼎 泥 摘 擢 敵 滴 的 笛 適 鏑 溺 哲",
		"93 4f 徹 撤 轍 迭 鉄 典 填 天 展 店 添 纏 甜 貼 転 顛",
		"93 5f 点 伝 殿 澱 田 電 兎 吐 堵 塗 妬 屠 徒 斗 杜 渡",
		"93 6f 登 菟 賭 途 都 鍍 砥 砺 努 度 土 奴 怒 倒 党 冬",
		"93 80 凍 刀 唐 塔 塘 套 宕 島 嶋 悼 投 搭 東 桃 梼 棟",
		"93 90 盗 淘 湯 涛 灯 燈 当 痘 祷 等 答 筒 糖 統 到",
		"93 9e    董 蕩 藤 討 謄 豆 踏 逃 透 鐙 陶 頭 騰 闘 働",
		"93 ae 動 同 堂 導 憧 撞 洞 瞳 童 胴 萄 道 銅 峠 鴇 匿",
		"93 be 得 徳 涜 特 督 禿 篤 毒 独 読 栃 橡 凸 突 椴 届",
		"93 ce 鳶 苫 寅 酉 瀞 噸 屯 惇 敦 沌 豚 遁 頓 呑 曇 鈍",
		"93 de 奈 那 内 乍 凪 薙 謎 灘 捺 鍋 楢 馴 縄 畷 南 楠",
		"93 ee 軟 難 汝 二 尼 弐 迩 匂 賑 肉 虹 廿 日 乳 入",
		"94 3f    如 尿 韮 任 妊 忍 認 濡 禰 祢 寧 葱 猫 熱 年",
		"94 4f 念 捻 撚 燃 粘 乃 廼 之 埜 嚢 悩 濃 納 能 脳 膿",
		"94 5f 農 覗 蚤 巴 把 播 覇 杷 波 派 琶 破 婆 罵 芭 馬",
		"94 6f 俳 廃 拝 排 敗 杯 盃 牌 背 肺 輩 配 倍 培 媒 梅",
		"94 80 楳 煤 狽 買 売 賠 陪 這 蝿 秤 矧 萩 伯 剥 博 拍",
		"94 90 柏 泊 白 箔 粕 舶 薄 迫 曝 漠 爆 縛 莫 駁 麦",
		"94 9e    函 箱 硲 箸 肇 筈 櫨 幡 肌 畑 畠 八 鉢 溌 発",
		"94 ae 醗 髪 伐 罰 抜 筏 閥 鳩 噺 塙 蛤 隼 伴 判 半 反",
		"94 be 叛 帆 搬 斑 板 氾 汎 版 犯 班 畔 繁 般 藩 販 範",
		"94 ce 釆 煩 頒 飯 挽 晩 番 盤 磐 蕃 蛮 匪 卑 否 妃 庇",
		"94 de 彼 悲 扉 批 披 斐 比 泌 疲 皮 碑 秘 緋 罷 肥 被",
		"94 ee 誹 費 避 非 飛 樋 簸 備 尾 微 枇 毘 琵 眉 美",
		"95 3f    鼻 柊 稗 匹 疋 髭 彦 膝 菱 肘 弼 必 畢 筆 逼",
		"95 4f 桧 姫 媛 紐 百 謬 俵 彪 標 氷 漂 瓢 票 表 評 豹",
		"95 5f 廟 描 病 秒 苗 錨 鋲 蒜 蛭 鰭 品 彬 斌 浜 瀕 貧",
		"95 6f 賓 頻 敏 瓶 不 付 埠 夫 婦 富 冨 布 府 怖 扶 敷",
		"95 80 斧 普 浮 父 符 腐 膚 芙 譜 負 賦 赴 阜 附 侮 撫",
		"95 90 武 舞 葡 蕪 部 封 楓 風 葺 蕗 伏 副 復 幅 服",
		"95 9e    福 腹 複 覆 淵 弗 払 沸 仏 物 鮒 分 吻 噴 墳",
		"95 ae 憤 扮 焚 奮 粉 糞 紛 雰 文 聞 丙 併 兵 塀 幣 平",
		"95 be 弊 柄 並 蔽 閉 陛 米 頁 僻 壁 癖 碧 別 瞥 蔑 箆",
		"95 ce 偏 変 片 篇 編 辺 返 遍 便 勉 娩 弁 鞭 保 舗 鋪",
		"95 de 圃 捕 歩 甫 補 輔 穂 募 墓 慕 戊 暮 母 簿 菩 倣",
		"95 ee 俸 包 呆 報 奉 宝 峰 峯 崩 庖 抱 捧 放 方 朋",
		"96 3f    法 泡 烹 砲 縫 胞 芳 萌 蓬 蜂 褒 訪 豊 邦 鋒",
		"96 4f 飽 鳳 鵬 乏 亡 傍 剖 坊 妨 帽 忘 忙 房 暴 望 某",
		"96 5f 棒 冒 紡 肪 膨 謀 貌 貿 鉾 防 吠 頬 北 僕 卜 墨",
		"96 6f 撲 朴 牧 睦 穆 釦 勃 没 殆 堀 幌 奔 本 翻 凡 盆",
		"96 80 摩 磨 魔 麻 埋 妹 昧 枚 毎 哩 槙 幕 膜 枕 鮪 柾",
		"96 90 鱒 桝 亦 俣 又 抹 末 沫 迄 侭 繭 麿 万 慢 満",
		"96 9e    漫 蔓 味 未 魅 巳 箕 岬 密 蜜 湊 蓑 稔 脈 妙",
		"96 ae 粍 民 眠 務 夢 無 牟 矛 霧 鵡 椋 婿 娘 冥 名 命",
		"96 be 明 盟 迷 銘 鳴 姪 牝 滅 免 棉 綿 緬 面 麺 摸 模",
		"96 ce 茂 妄 孟 毛 猛 盲 網 耗 蒙 儲 木 黙 目 杢 勿 餅",
		"96 de 尤 戻 籾 貰 問 悶 紋 門 匁 也 冶 夜 爺 耶 野 弥",
		"96 ee 矢 厄 役 約 薬 訳 躍 靖 柳 薮 鑓 愉 愈 油 癒",
		"97 3f    諭 輸 唯 佑 優 勇 友 宥 幽 悠 憂 揖 有 柚 湧",
		"97 4f 涌 猶 猷 由 祐 裕 誘 遊 邑 郵 雄 融 夕 予 余 与",
		"97 5f 誉 輿 預 傭 幼 妖 容 庸 揚 揺 擁 曜 楊 様 洋 溶",
		"97 6f 熔 用 窯 羊 耀 葉 蓉 要 謡 踊 遥 陽 養 慾 抑 欲",
		"97 80 沃 浴 翌 翼 淀 羅 螺 裸 来 莱 頼 雷 洛 絡 落 酪",
		"97 90 乱 卵 嵐 欄 濫 藍 蘭 覧 利 吏 履 李 梨 理 璃",
		"97 9e    痢 裏 裡 里 離 陸 律 率 立 葎 掠 略 劉 流 溜",
		"97 ae 琉 留 硫 粒 隆 竜 龍 侶 慮 旅 虜 了 亮 僚 両 凌",
		"97 be 寮 料 梁 涼 猟 療 瞭 稜 糧 良 諒 遼 量 陵 領 力",
		"97 ce 緑 倫 厘 林 淋 燐 琳 臨 輪 隣 鱗 麟 瑠 塁 涙 累",
		"97 de 類 令 伶 例 冷 励 嶺 怜 玲 礼 苓 鈴 隷 零 霊 麗",
		"97 ee 齢 暦 歴 列 劣 烈 裂 廉 恋 憐 漣 煉 簾 練 聯",
		"98 3f    蓮 連 錬 呂 魯 櫓 炉 賂 路 露 労 婁 廊 弄 朗",
		"98 4f 楼 榔 浪 漏 牢 狼 篭 老 聾 蝋 郎 六 麓 禄 肋 録",
		"98 5f 論 倭 和 話 歪 賄 脇 惑 枠 鷲 亙 亘 鰐 詫 藁 蕨",
		"98 6f 椀 湾 碗 腕",
		"98 80",
		"98 90",
		"98 9e    弌 丐 丕 个 丱 丶 丼 丿 乂 乖 乘 亂 亅 豫 亊",
		"98 ae 舒 弍 于 亞 亟 亠 亢 亰 亳 亶 从 仍 仄 仆 仂 仗",
		"98 be 仞 仭 仟 价 伉 佚 估 佛 佝 佗 佇 佶 侈 侏 侘 佻",
		"98 ce 佩 佰 侑 佯 來 侖 儘 俔 俟 俎 俘 俛 俑 俚 俐 俤",
		"98 de 俥 倚 倨 倔 倪 倥 倅 伜 俶 倡 倩 倬 俾 俯 們 倆",
		"98 ee 偃 假 會 偕 偐 偈 做 偖 偬 偸 傀 傚 傅 傴 傲",
		"99 3f    僉 僊 傳 僂 僖 僞 僥 僭 僣 僮 價 僵 儉 儁 儂",
		"99 4f 儖 儕 儔 儚 儡 儺 儷 儼 儻 儿 兀 兒 兌 兔 兢 竸",
		"99 5f 兩 兪 兮 冀 冂 囘 册 冉 冏 冑 冓 冕 冖 冤 冦 冢",
		"99 6f 冩 冪 冫 决 冱 冲 冰 况 冽 凅 凉 凛 几 處 凩 凭",
		"99 80 凰 凵 凾 刄 刋 刔 刎 刧 刪 刮 刳 刹 剏 剄 剋 剌",
		"99 90 剞 剔 剪 剴 剩 剳 剿 剽 劍 劔 劒 剱 劈 劑 辨",
		"99 9e    辧 劬 劭 劼 劵 勁 勍 勗 勞 勣 勦 飭 勠 勳 勵",
		"99 ae 勸 勹 匆 匈 甸 匍 匐 匏 匕 匚 匣 匯 匱 匳 匸 區",
		"99 be 卆 卅 丗 卉 卍 凖 卞 卩 卮 夘 卻 卷 厂 厖 厠 厦",
		"99 ce 厥 厮 厰 厶 參 簒 雙 叟 曼 燮 叮 叨 叭 叺 吁 吽",
		"99 de 呀 听 吭 吼 吮 吶 吩 吝 呎 咏 呵 咎 呟 呱 呷 呰",
		"99 ee 咒 呻 咀 呶 咄 咐 咆 哇 咢 咸 咥 咬 哄 哈 咨",
		"9a 3f    咫 哂 咤 咾 咼 哘 哥 哦 唏 唔 哽 哮 哭 哺 哢",
		"9a 4f 唹 啀 啣 啌 售 啜 啅 啖 啗 唸 唳 啝 喙 喀 咯 喊",
		"9a 5f 喟 啻 啾 喘 喞 單 啼 喃 喩 喇 喨 嗚 嗅 嗟 嗄 嗜",
		"9a 6f 嗤 嗔 嘔 嗷 嘖 嗾 嗽 嘛 嗹 噎 噐 營 嘴 嘶 嘲 嘸",
		"9a 80 噫 噤 嘯 噬 噪 嚆 嚀 嚊 嚠 嚔 嚏 嚥 嚮 嚶 嚴 囂",
		"9a 90 嚼 囁 囃 囀 囈 囎 囑 囓 囗 囮 囹 圀 囿 圄 圉",
		"9a 9e    圈 國 圍 圓 團 圖 嗇 圜 圦 圷 圸 坎 圻 址 坏",
		"9a ae 坩 埀 垈 坡 坿 垉 垓 垠 垳 垤 垪 垰 埃 埆 埔 埒",
		"9a be 埓 堊 埖 埣 堋 堙 堝 塲 堡 塢 塋 塰 毀 塒 堽 塹",
		"9a ce 墅 墹 墟 墫 墺 壞 墻 墸 墮 壅 壓 壑 壗 壙 壘 壥",
		"9a de 壜 壤 壟 壯 壺 壹 壻 壼 壽 夂 夊 夐 夛 梦 夥 夬",
		"9a ee 夭 夲 夸 夾 竒 奕 奐 奎 奚 奘 奢 奠 奧 奬 奩",
		"9b 3f    奸 妁 妝 佞 侫 妣 妲 姆 姨 姜 妍 姙 姚 娥 娟",
		"9b 4f 娑 娜 娉 娚 婀 婬 婉 娵 娶 婢 婪 媚 媼 媾 嫋 嫂",
		"9b 5f 媽 嫣 嫗 嫦 嫩 嫖 嫺 嫻 嬌 嬋 嬖 嬲 嫐 嬪 嬶 嬾",
		"9b 6f 孃 孅 孀 孑 孕 孚 孛 孥 孩 孰 孳 孵 學 斈 孺 宀",
		"9b 80 它 宦 宸 寃 寇 寉 寔 寐 寤 實 寢 寞 寥 寫 寰 寶",
		"9b 90 寳 尅 將 專 對 尓 尠 尢 尨 尸 尹 屁 屆 屎 屓",
		"9b 9e    屐 屏 孱 屬 屮 乢 屶 屹 岌 岑 岔 妛 岫 岻 岶",
		"9b ae 岼 岷 峅 岾 峇 峙 峩 峽 峺 峭 嶌 峪 崋 崕 崗 嵜",
		"9b be 崟 崛 崑 崔 崢 崚 崙 崘 嵌 嵒 嵎 嵋 嵬 嵳 嵶 嶇",
		"9b ce 嶄 嶂 嶢 嶝 嶬 嶮 嶽 嶐 嶷 嶼 巉 巍 巓 巒 巖 巛",
		"9b de 巫 已 巵 帋 帚 帙 帑 帛 帶 帷 幄 幃 幀 幎 幗 幔",
		"9b ee 幟 幢 幤 幇 幵 并 幺 麼 广 庠 廁 廂 廈 廐 廏",
		"9c 3f    廖 廣 廝 廚 廛 廢 廡 廨 廩 廬 廱 廳 廰 廴 廸",
		"9c 4f 廾 弃 弉 彝 彜 弋 弑 弖 弩 弭 弸 彁 彈 彌 彎 弯",
		"9c 5f 彑 彖 彗 彙 彡 彭 彳 彷 徃 徂 彿 徊 很 徑 徇 從",
		"9c 6f 徙 徘 徠 徨 徭 徼 忖 忻 忤 忸 忱 忝 悳 忿 怡 恠",
		"9c 80 怙 怐 怩 怎 怱 怛 怕 怫 怦 怏 怺 恚 恁 恪 恷 恟",
		"9c 90 恊 恆 恍 恣 恃 恤 恂 恬 恫 恙 悁 悍 惧 悃 悚",
		"9c 9e    悄 悛 悖 悗 悒 悧 悋 惡 悸 惠 惓 悴 忰 悽 惆",
		"9c ae 悵 惘 慍 愕 愆 惶 惷 愀 惴 惺 愃 愡 惻 惱 愍 愎",
		"9c be 慇 愾 愨 愧 慊 愿 愼 愬 愴 愽 慂 慄 慳 慷 慘 慙",
		"9c ce 慚 慫 慴 慯 慥 慱 慟 慝 慓 慵 憙 憖 憇 憬 憔 憚",
		"9c de 憊 憑 憫 憮 懌 懊 應 懷 懈 懃 懆 憺 懋 罹 懍 懦",
		"9c ee 懣 懶 懺 懴 懿 懽 懼 懾 戀 戈 戉 戍 戌 戔 戛",
		"9d 3f    戞 戡 截 戮 戰 戲 戳 扁 扎 扞 扣 扛 扠 扨 扼",
		"9d 4f 抂 抉 找 抒 抓 抖 拔 抃 抔 拗 拑 抻 拏 拿 拆 擔",
		"9d 5f 拈 拜 拌 拊 拂 拇 抛 拉 挌 拮 拱 挧 挂 挈 拯 拵",
		"9d 6f 捐 挾 捍 搜 捏 掖 掎 掀 掫 捶 掣 掏 掉 掟 掵 捫",
		"9d 80 捩 掾 揩 揀 揆 揣 揉 插 揶 揄 搖 搴 搆 搓 搦 搶",
		"9d 90 攝 搗 搨 搏 摧 摯 摶 摎 攪 撕 撓 撥 撩 撈 撼",
		"9d 9e    據 擒 擅 擇 撻 擘 擂 擱 擧 舉 擠 擡 抬 擣 擯",
		"9d ae 攬 擶 擴 擲 擺 攀 擽 攘 攜 攅 攤 攣 攫 攴 攵 攷",
		"9d be 收 攸 畋 效 敖 敕 敍 敘 敞 敝 敲 數 斂 斃 變 斛",
		"9d ce 斟 斫 斷 旃 旆 旁 旄 旌 旒 旛 旙 无 旡 旱 杲 昊",
		"9d de 昃 旻 杳 昵 昶 昴 昜 晏 晄 晉 晁 晞 晝 晤 晧 晨",
		"9d ee 晟 晢 晰 暃 暈 暎 暉 暄 暘 暝 曁 暹 曉 暾 暼",
		"9e 3f    曄 暸 曖 曚 曠 昿 曦 曩 曰 曵 曷 朏 朖 朞 朦",
		"9e 4f 朧 霸 朮 朿 朶 杁 朸 朷 杆 杞 杠 杙 杣 杤 枉 杰",
		"9e 5f 枩 杼 杪 枌 枋 枦 枡 枅 枷 柯 枴 柬 枳 柩 枸 柤",
		"9e 6f 柞 柝 柢 柮 枹 柎 柆 柧 檜 栞 框 栩 桀 桍 栲 桎",
		"9e 80 梳 栫 桙 档 桷 桿 梟 梏 梭 梔 條 梛 梃 檮 梹 桴",
		"9e 90 梵 梠 梺 椏 梍 桾 椁 棊 椈 棘 椢 椦 棡 椌 棍",
		"9e 9e    棔 棧 棕 椶 椒 椄 棗 棣 椥 棹 棠 棯 椨 椪 椚",
		"9e ae 椣 椡 棆 楹 楷 楜 楸 楫 楔 楾 楮 椹 楴 椽 楙 椰",
		"9e be 楡 楞 楝 榁 楪 榲 榮 槐 榿 槁 槓 榾 槎 寨 槊 槝",
		"9e ce 榻 槃 榧 樮 榑 榠 榜 榕 榴 槞 槨 樂 樛 槿 權 槹",
		"9e de 槲 槧 樅 榱 樞 槭 樔 槫 樊 樒 櫁 樣 樓 橄 樌 橲",
		"9e ee 樶 橸 橇 橢 橙 橦 橈 樸 樢 檐 檍 檠 檄 檢 檣",
		"9f 3f    檗 蘗 檻 櫃 櫂 檸 檳 檬 櫞 櫑 櫟 檪 櫚 櫪 櫻",
		"9f 4f 欅 蘖 櫺 欒 欖 鬱 欟 欸 欷 盜 欹 飮 歇 歃 歉 歐",
		"9f 5f 歙 歔 歛 歟 歡 歸 歹 歿 殀 殄 殃 殍 殘 殕 殞 殤",
		"9f 6f 殪 殫 殯 殲 殱 殳 殷 殼 毆 毋 毓 毟 毬 毫 毳 毯",
		"9f 80 麾 氈 氓 气 氛 氤 氣 汞 汕 汢 汪 沂 沍 沚 沁 沛",
		"9f 90 汾 汨 汳 沒 沐 泄 泱 泓 沽 泗 泅 泝 沮 沱 沾",
		"9f 9e    沺 泛 泯 泙 泪 洟 衍 洶 洫 洽 洸 洙 洵 洳 洒",
		"9f ae 洌 浣 涓 浤 浚 浹 浙 涎 涕 濤 涅 淹 渕 渊 涵 淇",
		"9f be 淦 涸 淆 淬 淞 淌 淨 淒 淅 淺 淙 淤 淕 淪 淮 渭",
		"9f ce 湮 渮 渙 湲 湟 渾 渣 湫 渫 湶 湍 渟 湃 渺 湎 渤",
		"9f de 滿 渝 游 溂 溪 溘 滉 溷 滓 溽 溯 滄 溲 滔 滕 溏",
		"9f ee 溥 滂 溟 潁 漑 灌 滬 滸 滾 漿 滲 漱 滯 漲 滌",
		"e0 3f    漾 漓 滷 澆 潺 潸 澁 澀 潯 潛 濳 潭 澂 潼 潘",
		"e0 4f 澎 澑 濂 潦 澳 澣 澡 澤 澹 濆 澪 濟 濕 濬 濔 濘",
		"e0 5f 濱 濮 濛 瀉 瀋 濺 瀑 瀁 瀏 濾 瀛 瀚 潴 瀝 瀘 瀟",
		"e0 6f 瀰 瀾 瀲 灑 灣 炙 炒 炯 烱 炬 炸 炳 炮 烟 烋 烝",
		"e0 80 烙 焉 烽 焜 焙 煥 煕 熈 煦 煢 煌 煖 煬 熏 燻 熄",
		"e0 90 熕 熨 熬 燗 熹 熾 燒 燉 燔 燎 燠 燬 燧 燵 燼",
		"e0 9e    燹 燿 爍 爐 爛 爨 爭 爬 爰 爲 爻 爼 爿 牀 牆",
		"e0 ae 牋 牘 牴 牾 犂 犁 犇 犒 犖 犢 犧 犹 犲 狃 狆 狄",
		"e0 be 狎 狒 狢 狠 狡 狹 狷 倏 猗 猊 猜 猖 猝 猴 猯 猩",
		"e0 ce 猥 猾 獎 獏 默 獗 獪 獨 獰 獸 獵 獻 獺 珈 玳 珎",
		"e0 de 玻 珀 珥 珮 珞 璢 琅 瑯 琥 珸 琲 琺 瑕 琿 瑟 瑙",
		"e0 ee 瑁 瑜 瑩 瑰 瑣 瑪 瑶 瑾 璋 璞 璧 瓊 瓏 瓔 珱",
		"e1 3f    瓠 瓣 瓧 瓩 瓮 瓲 瓰 瓱 瓸 瓷 甄 甃 甅 甌 甎",
		"e1 4f 甍 甕 甓 甞 甦 甬 甼 畄 畍 畊 畉 畛 畆 畚 畩 畤",
		"e1 5f 畧 畫 畭 畸 當 疆 疇 畴 疊 疉 疂 疔 疚 疝 疥 疣",
		"e1 6f 痂 疳 痃 疵 疽 疸 疼 疱 痍 痊 痒 痙 痣 痞 痾 痿",
		"e1 80 痼 瘁 痰 痺 痲 痳 瘋 瘍 瘉 瘟 瘧 瘠 瘡 瘢 瘤 瘴",
		"e1 90 瘰 瘻 癇 癈 癆 癜 癘 癡 癢 癨 癩 癪 癧 癬 癰",
		"e1 9e    癲 癶 癸 發 皀 皃 皈 皋 皎 皖 皓 皙 皚 皰 皴",
		"e1 ae 皸 皹 皺 盂 盍 盖 盒 盞 盡 盥 盧 盪 蘯 盻 眈 眇",
		"e1 be 眄 眩 眤 眞 眥 眦 眛 眷 眸 睇 睚 睨 睫 睛 睥 睿",
		"e1 ce 睾 睹 瞎 瞋 瞑 瞠 瞞 瞰 瞶 瞹 瞿 瞼 瞽 瞻 矇 矍",
		"e1 de 矗 矚 矜 矣 矮 矼 砌 砒 礦 砠 礪 硅 碎 硴 碆 硼",
		"e1 ee 碚 碌 碣 碵 碪 碯 磑 磆 磋 磔 碾 碼 磅 磊 磬",
		"e2 3f    磧 磚 磽 磴 礇 礒 礑 礙 礬 礫 祀 祠 祗 祟 祚",
		"e2 4f 祕 祓 祺 祿 禊 禝 禧 齋 禪 禮 禳 禹 禺 秉 秕 秧",
		"e2 5f 秬 秡 秣 稈 稍 稘 稙 稠 稟 禀 稱 稻 稾 稷 穃 穗",
		"e2 6f 穉 穡 穢 穩 龝 穰 穹 穽 窈 窗 窕 窘 窖 窩 竈 窰",
		"e2 80 窶 竅 竄 窿 邃 竇 竊 竍 竏 竕 竓 站 竚 竝 竡 竢",
		"e2 90 竦 竭 竰 笂 笏 笊 笆 笳 笘 笙 笞 笵 笨 笶 筐",
		"e2 9e    筺 笄 筍 笋 筌 筅 筵 筥 筴 筧 筰 筱 筬 筮 箝",
		"e2 ae 箘 箟 箍 箜 箚 箋 箒 箏 筝 箙 篋 篁 篌 篏 箴 篆",
		"e2 be 篝 篩 簑 簔 篦 篥 籠 簀 簇 簓 篳 篷 簗 簍 篶 簣",
		"e2 ce 簧 簪 簟 簷 簫 簽 籌 籃 籔 籏 籀 籐 籘 籟 籤 籖",
		"e2 de 籥 籬 籵 粃 粐 粤 粭 粢 粫 粡 粨 粳 粲 粱 粮 粹",
		"e2 ee 粽 糀 糅 糂 糘 糒 糜 糢 鬻 糯 糲 糴 糶 糺 紆",
		"e3 3f    紂 紜 紕 紊 絅 絋 紮 紲 紿 紵 絆 絳 絖 絎 絲",
		"e3 4f 絨 絮 絏 絣 經 綉 絛 綏 絽 綛 綺 綮 綣 綵 緇 綽",
		"e3 5f 綫 總 綢 綯 緜 綸 綟 綰 緘 緝 緤 緞 緻 緲 緡 縅",
		"e3 6f 縊 縣 縡 縒 縱 縟 縉 縋 縢 繆 繦 縻 縵 縹 繃 縷",
		"e3 80 縲 縺 繧 繝 繖 繞 繙 繚 繹 繪 繩 繼 繻 纃 緕 繽",
		"e3 90 辮 繿 纈 纉 續 纒 纐 纓 纔 纖 纎 纛 纜 缸 缺",
		"e3 9e    罅 罌 罍 罎 罐 网 罕 罔 罘 罟 罠 罨 罩 罧 罸",
		"e3 ae 羂 羆 羃 羈 羇 羌 羔 羞 羝 羚 羣 羯 羲 羹 羮 羶",
		"e3 be 羸 譱 翅 翆 翊 翕 翔 翡 翦 翩 翳 翹 飜 耆 耄 耋",
		"e3 ce 耒 耘 耙 耜 耡 耨 耿 耻 聊 聆 聒 聘 聚 聟 聢 聨",
		"e3 de 聳 聲 聰 聶 聹 聽 聿 肄 肆 肅 肛 肓 肚 肭 冐 肬",
		"e3 ee 胛 胥 胙 胝 胄 胚 胖 脉 胯 胱 脛 脩 脣 脯 腋",
		"e4 3f    隋 腆 脾 腓 腑 胼 腱 腮 腥 腦 腴 膃 膈 膊 膀",
		"e4 4f 膂 膠 膕 膤 膣 腟 膓 膩 膰 膵 膾 膸 膽 臀 臂 膺",
		"e4 5f 臉 臍 臑 臙 臘 臈 臚 臟 臠 臧 臺 臻 臾 舁 舂 舅",
		"e4 6f 與 舊 舍 舐 舖 舩 舫 舸 舳 艀 艙 艘 艝 艚 艟 艤",
		"e4 80 艢 艨 艪 艫 舮 艱 艷 艸 艾 芍 芒 芫 芟 芻 芬 苡",
		"e4 90 苣 苟 苒 苴 苳 苺 莓 范 苻 苹 苞 茆 苜 茉 苙",
		"e4 9e    茵 茴 茖 茲 茱 荀 茹 荐 荅 茯 茫 茗 茘 莅 莚",
		"e4 ae 莪 莟 莢 莖 茣 莎 莇 莊 荼 莵 荳 荵 莠 莉 莨 菴",
		"e4 be 萓 菫 菎 菽 萃 菘 萋 菁 菷 萇 菠 菲 萍 萢 萠 莽",
		"e4 ce 萸 蔆 菻 葭 萪 萼 蕚 蒄 葷 葫 蒭 葮 蒂 葩 葆 萬",
		"e4 de 葯 葹 萵 蓊 葢 蒹 蒿 蒟 蓙 蓍 蒻 蓚 蓐 蓁 蓆 蓖",
		"e4 ee 蒡 蔡 蓿 蓴 蔗 蔘 蔬 蔟 蔕 蔔 蓼 蕀 蕣 蕘 蕈",
		"e5 3f    蕁 蘂 蕋 蕕 薀 薤 薈 薑 薊 薨 蕭 薔 薛 藪 薇",
		"e5 4f 薜 蕷 蕾 薐 藉 薺 藏 薹 藐 藕 藝 藥 藜 藹 蘊 蘓",
		"e5 5f 蘋 藾 藺 蘆 蘢 蘚 蘰 蘿 虍 乕 虔 號 虧 虱 蚓 蚣",
		"e5 6f 蚩 蚪 蚋 蚌 蚶 蚯 蛄 蛆 蚰 蛉 蠣 蚫 蛔 蛞 蛩 蛬",
		"e5 80 蛟 蛛 蛯 蜒 蜆 蜈 蜀 蜃 蛻 蜑 蜉 蜍 蛹 蜊 蜴 蜿",
		"e5 90 蜷 蜻 蜥 蜩 蜚 蝠 蝟 蝸 蝌 蝎 蝴 蝗 蝨 蝮 蝙",
		"e5 9e    蝓 蝣 蝪 蠅 螢 螟 螂 螯 蟋 螽 蟀 蟐 雖 螫 蟄",
		"e5 ae 螳 蟇 蟆 螻 蟯 蟲 蟠 蠏 蠍 蟾 蟶 蟷 蠎 蟒 蠑 蠖",
		"e5 be 蠕 蠢 蠡 蠱 蠶 蠹 蠧 蠻 衄 衂 衒 衙 衞 衢 衫 袁",
		"e5 ce 衾 袞 衵 衽 袵 衲 袂 袗 袒 袮 袙 袢 袍 袤 袰 袿",
		"e5 de 袱 裃 裄 裔 裘 裙 裝 裹 褂 裼 裴 裨 裲 褄 褌 褊",
		"e5 ee 褓 襃 褞 褥 褪 褫 襁 襄 褻 褶 褸 襌 褝 襠 襞",
		"e6 3f    襦 襤 襭 襪 襯 襴 襷 襾 覃 覈 覊 覓 覘 覡 覩",
		"e6 4f 覦 覬 覯 覲 覺 覽 覿 觀 觚 觜 觝 觧 觴 觸 訃 訖",
		"e6 5f 訐 訌 訛 訝 訥 訶 詁 詛 詒 詆 詈 詼 詭 詬 詢 誅",
		"e6 6f 誂 誄 誨 誡 誑 誥 誦 誚 誣 諄 諍 諂 諚 諫 諳 諧",
		"e6 80 諤 諱 謔 諠 諢 諷 諞 諛 謌 謇 謚 諡 謖 謐 謗 謠",
		"e6 90 謳 鞫 謦 謫 謾 謨 譁 譌 譏 譎 證 譖 譛 譚 譫",
		"e6 9e    譟 譬 譯 譴 譽 讀 讌 讎 讒 讓 讖 讙 讚 谺 豁",
		"e6 ae 谿 豈 豌 豎 豐 豕 豢 豬 豸 豺 貂 貉 貅 貊 貍 貎",
		"e6 be 貔 豼 貘 戝 貭 貪 貽 貲 貳 貮 貶 賈 賁 賤 賣 賚",
		"e6 ce 賽 賺 賻 贄 贅 贊 贇 贏 贍 贐 齎 贓 賍 贔 贖 赧",
		"e6 de 赭 赱 赳 趁 趙 跂 趾 趺 跏 跚 跖 跌 跛 跋 跪 跫",
		"e6 ee 跟 跣 跼 踈 踉 跿 踝 踞 踐 踟 蹂 踵 踰 踴 蹊",
		"e7 3f    蹇 蹉 蹌 蹐 蹈 蹙 蹤 蹠 踪 蹣 蹕 蹶 蹲 蹼 躁",
		"e7 4f 躇 躅 躄 躋 躊 躓 躑 躔 躙 躪 躡 躬 躰 軆 躱 躾",
		"e7 5f 軅 軈 軋 軛 軣 軼 軻 軫 軾 輊 輅 輕 輒 輙 輓 輜",
		"e7 6f 輟 輛 輌 輦 輳 輻 輹 轅 轂 輾 轌 轉 轆 轎 轗 轜",
		"e7 80 轢 轣 轤 辜 辟 辣 辭 辯 辷 迚 迥 迢 迪 迯 邇 迴",
		"e7 90 逅 迹 迺 逑 逕 逡 逍 逞 逖 逋 逧 逶 逵 逹 迸",
		"e7 9e    遏 遐 遑 遒 逎 遉 逾 遖 遘 遞 遨 遯 遶 隨 遲",
		"e7 ae 邂 遽 邁 邀 邊 邉 邏 邨 邯 邱 邵 郢 郤 扈 郛 鄂",
		"e7 be 鄒 鄙 鄲 鄰 酊 酖 酘 酣 酥 酩 酳 酲 醋 醉 醂 醢",
		"e7 ce 醫 醯 醪 醵 醴 醺 釀 釁 釉 釋 釐 釖 釟 釡 釛 釼",
		"e7 de 釵 釶 鈞 釿 鈔 鈬 鈕 鈑 鉞 鉗 鉅 鉉 鉤 鉈 銕 鈿",
		"e7 ee 鉋 鉐 銜 銖 銓 銛 鉚 鋏 銹 銷 鋩 錏 鋺 鍄 錮",
		"e8 3f    錙 錢 錚 錣 錺 錵 錻 鍜 鍠 鍼 鍮 鍖 鎰 鎬 鎭",
		"e8 4f 鎔 鎹 鏖 鏗 鏨 鏥 鏘 鏃 鏝 鏐 鏈 鏤 鐚 鐔 鐓 鐃",
		"e8 5f 鐇 鐐 鐶 鐫 鐵 鐡 鐺 鑁 鑒 鑄 鑛 鑠 鑢 鑞 鑪 鈩",
		"e8 6f 鑰 鑵 鑷 鑽 鑚 鑼 鑾 钁 鑿 閂 閇 閊 閔 閖 閘 閙",
		"e8 80 閠 閨 閧 閭 閼 閻 閹 閾 闊 濶 闃 闍 闌 闕 闔 闖",
		"e8 90 關 闡 闥 闢 阡 阨 阮 阯 陂 陌 陏 陋 陷 陜 陞",
		"e8 9e    陝 陟 陦 陲 陬 隍 隘 隕 隗 險 隧 隱 隲 隰 隴",
		"e8 ae 隶 隸 隹 雎 雋 雉 雍 襍 雜 霍 雕 雹 霄 霆 霈 霓",
		"e8 be 霎 霑 霏 霖 霙 霤 霪 霰 霹 霽 霾 靄 靆 靈 靂 靉",
		"e8 ce 靜 靠 靤 靦 靨 勒 靫 靱 靹 鞅 靼 鞁 靺 鞆 鞋 鞏",
		"e8 de 鞐 鞜 鞨 鞦 鞣 鞳 鞴 韃 韆 韈 韋 韜 韭 齏 韲 竟",
		"e8 ee 韶 韵 頏 頌 頸 頤 頡 頷 頽 顆 顏 顋 顫 顯 顰",
		"e9 3f    顱 顴 顳 颪 颯 颱 颶 飄 飃 飆 飩 飫 餃 餉 餒",
		"e9 4f 餔 餘 餡 餝 餞 餤 餠 餬 餮 餽 餾 饂 饉 饅 饐 饋",
		"e9 5f 饑 饒 饌 饕 馗 馘 馥 馭 馮 馼 駟 駛 駝 駘 駑 駭",
		"e9 6f 駮 駱 駲 駻 駸 騁 騏 騅 駢 騙 騫 騷 驅 驂 驀 驃",
		"e9 80 騾 驕 驍 驛 驗 驟 驢 驥 驤 驩 驫 驪 骭 骰 骼 髀",
		"e9 90 髏 髑 髓 體 髞 髟 髢 髣 髦 髯 髫 髮 髴 髱 髷",
		"e9 9e    髻 鬆 鬘 鬚 鬟 鬢 鬣 鬥 鬧 鬨 鬩 鬪 鬮 鬯 鬲",
		"e9 ae 魄 魃 魏 魍 魎 魑 魘 魴 鮓 鮃 鮑 鮖 鮗 鮟 鮠 鮨",
		"e9 be 鮴 鯀 鯊 鮹 鯆 鯏 鯑 鯒 鯣 鯢 鯤 鯔 鯡 鰺 鯲 鯱",
		"e9 ce 鯰 鰕 鰔 鰉 鰓 鰌 鰆 鰈 鰒 鰊 鰄 鰮 鰛 鰥 鰤 鰡",
		"e9 de 鰰 鱇 鰲 鱆 鰾 鱚 鱠 鱧 鱶 鱸 鳧 鳬 鳰 鴉 鴈 鳫",
		"e9 ee 鴃 鴆 鴪 鴦 鶯 鴣 鴟 鵄 鴕 鴒 鵁 鴿 鴾 鵆 鵈",
		"ea 3f    鵝 鵞 鵤 鵑 鵐 鵙 鵲 鶉 鶇 鶫 鵯 鵺 鶚 鶤 鶩",
		"ea 4f 鶲 鷄 鷁 鶻 鶸 鶺 鷆 鷏 鷂 鷙 鷓 鷸 鷦 鷭 鷯 鷽",
		"ea 5f 鸚 鸛 鸞 鹵 鹹 鹽 麁 麈 麋 麌 麒 麕 麑 麝 麥 麩",
		"ea 6f 麸 麪 麭 靡 黌 黎 黏 黐 黔 黜 點 黝 黠 黥 黨 黯",
		"ea 80 黴 黶 黷 黹 黻 黼 黽 鼇 鼈 皷 鼕 鼡 鼬 鼾 齊 齒",
		"ea 90 齔 齣 齟 齠 齡 齦 齧 齬 齪 齷 齲 齶 龕 龜 龠",
		"ea 9e    堯 槇 遙 瑤 凜 熙",
		"ed 40 纊 褜 鍈 銈 蓜 俉 炻 昱 棈 鋹 曻 彅 丨 仡 仼 伀",
		"ed 50 伃 伹 佖 侒 侊 侚 侔 俍 偀 倢 俿 倞 偆 偰 偂 傔",
		"ed 60 僴 僘 兊 兤 冝 冾 凬 刕 劜 劦 勀 勛 匀 匇 匤 卲",
		"ed 70 厓 厲 叝 﨎 咜 咊 咩 哿 喆 坙 坥 垬 埈 埇 﨏",
		"ed 80 塚 增 墲 夋 奓 奛 奝 奣 妤 妺 孖 寀 甯 寘 寬 尞",
		"ed 90 岦 岺 峵 崧 嵓 﨑 嵂 嵭 嶸 嶹 巐 弡 弴 彧 德 忞",
		"ed a0 恝 悅 悊 惞 惕 愠 惲 愑 愷 愰 憘 戓 抦 揵 摠 撝",
		"ed b0 擎 敎 昀 昕 昻 昉 昮 昞 昤 晥 晗 晙 晴 晳 暙 暠",
		"ed c0 暲 暿 曺 朎 朗 杦 枻 桒 柀 栁 桄 棏 﨓 楨 﨔 榘",
		"ed d0 槢 樰 橫 橆 橳 橾 櫢 櫤 毖 氿 汜 沆 汯 泚 洄 涇",
		"ed e0 浯 涖 涬 淏 淸 淲 淼 渹 湜 渧 渼 溿 澈 澵 濵 瀅",
		"ed f0 瀇 瀨 炅 炫 焏 焄 煜 煆 煇 凞 燁 燾 犱",
		"ee 40 犾 猤 猪 獷 玽 珉 珖 珣 珒 琇 珵 琦 琪 琩 琮 瑢",
		"ee 50 璉 璟 甁 畯 皂 皜 皞 皛 皦 益 睆 劯 砡 硎 硤 硺",
		"ee 60 礰 礼 神 祥 禔 福 禛 竑 竧 靖 竫 箞 精 絈 絜 綷",
		"ee 70 綠 緖 繒 罇 羡 羽 茁 荢 荿 菇 菶 葈 蒴 蕓 蕙",
		"ee 80 蕫 﨟 薰 蘒 﨡 蠇 裵 訒 訷 詹 誧 誾 諟 諸 諶 譓",
		"ee 90 譿 賰 賴 贒 赶 﨣 軏 﨤 逸 遧 郞 都 鄕 鄧 釚 釗",
		"ee a0 釞 釭 釮 釤 釥 鈆 鈐 鈊 鈺 鉀 鈼 鉎 鉙 鉑 鈹 鉧",
		"ee b0 銧 鉷 鉸 鋧 鋗 鋙 鋐 﨧 鋕 鋠 鋓 錥 錡 鋻 﨨 錞",
		"ee c0 鋿 錝 錂 鍰 鍗 鎤 鏆 鏞 鏸 鐱 鑅 鑈 閒 隆 﨩 隝",
		"ee d0 隯 霳 霻 靃 靍 靏 靑 靕 顗 顥 飯 飼 餧 館 馞 驎",
		"ee e0 髙 髜 魵 魲 鮏 鮱 鮻 鰀 鵰 鵫 鶴 鸙 黑 　 　 ⅰ",
		"ee f0 ⅱ ⅲ ⅳ ⅴ ⅵ ⅶ ⅷ ⅸ ⅹ ￢ ￤ ＇ ＂",
		"f0 3f                  ",
		"f0 4f                ",
		"f0 5f                ",
		"f0 6f                ",
		"f0 80                ",
		"f0 90               ",
		"f0 9e                  ",
		"f0 ae                ",
		"f0 be                ",
		"f0 ce                ",
		"f0 de                ",
		"f0 ee               ",
		"f1 3f                  ",
		"f1 4f                ",
		"f1 5f                ",
		"f1 6f                ",
		"f1 80                ",
		"f1 90               ",
		"f1 9e                  ",
		"f1 ae                ",
		"f1 be                ",
		"f1 ce                ",
		"f1 de                ",
		"f1 ee               ",
		"f2 3f                  ",
		"f2 4f                ",
		"f2 5f                ",
		"f2 6f                ",
		"f2 80                ",
		"f2 90               ",
		"f2 9e                  ",
		"f2 ae                ",
		"f2 be                ",
		"f2 ce                ",
		"f2 de                ",
		"f2 ee               ",
		"f3 3f                  ",
		"f3 4f                ",
		"f3 5f                ",
		"f3 6f                ",
		"f3 80                ",
		"f3 90               ",
		"f3 9e                  ",
		"f3 ae                ",
		"f3 be                ",
		"f3 ce                ",
		"f3 de                ",
		"f3 ee               ",
		"f4 3f                  ",
		"f4 4f                ",
		"f4 5f                ",
		"f4 6f                ",
		"f4 80                ",
		"f4 90               ",
		"f4 9e                  ",
		"f4 ae                ",
		"f4 be                ",
		"f4 ce                ",
		"f4 de                ",
		"f4 ee               ",
		"f5 3f                  ",
		"f5 4f                ",
		"f5 5f                ",
		"f5 6f                ",
		"f5 80                ",
		"f5 90               ",
		"f5 9e                  ",
		"f5 ae                ",
		"f5 be                ",
		"f5 ce                ",
		"f5 de                ",
		"f5 ee               ",
		"f6 3f                  ",
		"f6 4f                ",
		"f6 5f                ",
		"f6 6f                ",
		"f6 80                ",
		"f6 90               ",
		"f6 9e                  ",
		"f6 ae                ",
		"f6 be                ",
		"f6 ce                ",
		"f6 de                ",
		"f6 ee               ",
		"f7 3f                  ",
		"f7 4f                ",
		"f7 5f                ",
		"f7 6f                ",
		"f7 80                ",
		"f7 90               ",
		"f7 9e                  ",
		"f7 ae                ",
		"f7 be                ",
		"f7 ce                ",
		"f7 de                ",
		"f7 ee               ",
		"f8 3f                  ",
		"f8 4f                ",
		"f8 5f                ",
		"f8 6f                ",
		"f8 80                ",
		"f8 90               ",
		"f8 9e                  ",
		"f8 ae                ",
		"f8 be                ",
		"f8 ce                ",
		"f8 de                ",
		"f8 ee               ",
		"f9 3f    �@ �A �B �C �D �E �F �G �H �I �J �K �L �M �N",
		"f9 4f �O �P �Q �R �S �T �U �V �W �X �Y �Z �[ �\\\\ �] �^",
		"f9 5f �_ �` �a �b �c �d �e �f �g �h �i �j �k �l �m �n",
		"f9 6f �o �p �q �r �s �t �u �v �w �x �y �z �{ �| �} �~",
		"f9 80 � � � � � � � � � � � � � � � �",
		"f9 90 � � � � � � � � � � � � � � �",
		"f9 9e    � � � � � � � � � � � � � � �",
		"f9 ae � � � � � � � � � � � � � � � �",
		"f9 be � � � � � � � � � � � � � � � �",
		"f9 ce � � � � � � � � � � � � � � � �",
		"f9 de � � � � � � � � � � � � � � � �",
		"f9 ee � � � � � � � � � � � � � � �",
		"fa 40 ⅰ ⅱ ⅲ ⅳ ⅴ ⅵ ⅶ ⅷ ⅸ ⅹ Ⅰ Ⅱ Ⅲ Ⅳ Ⅴ Ⅵ",
		"fa 50 Ⅶ Ⅷ Ⅸ Ⅹ ￢ ￤ ＇ ＂ ㈱ № ℡ ∵ 纊 褜 鍈 銈",
		"fa 60 蓜 俉 炻 昱 棈 鋹 曻 彅 丨 仡 仼 伀 伃 伹 佖 侒",
		"fa 70 侊 侚 侔 俍 偀 倢 俿 倞 偆 偰 偂 傔 僴 僘 兊",
		"fa 80 兤 冝 冾 凬 刕 劜 劦 勀 勛 匀 匇 匤 卲 厓 厲 叝",
		"fa 90 﨎 咜 咊 咩 哿 喆 坙 坥 垬 埈 埇 﨏 塚 增 墲 夋",
		"fa a0 奓 奛 奝 奣 妤 妺 孖 寀 甯 寘 寬 尞 岦 岺 峵 崧",
		"fa b0 嵓 﨑 嵂 嵭 嶸 嶹 巐 弡 弴 彧 德 忞 恝 悅 悊 惞",
		"fa c0 惕 愠 惲 愑 愷 愰 憘 戓 抦 揵 摠 撝 擎 敎 昀 昕",
		"fa d0 昻 昉 昮 昞 昤 晥 晗 晙 晴 晳 暙 暠 暲 暿 曺 朎",
		"fa e0 朗 杦 枻 桒 柀 栁 桄 棏 﨓 楨 﨔 榘 槢 樰 橫 橆",
		"fa f0 橳 橾 櫢 櫤 毖 氿 汜 沆 汯 泚 洄 涇 浯",
		"fb 40 涖 涬 淏 淸 淲 淼 渹 湜 渧 渼 溿 澈 澵 濵 瀅 瀇",
		"fb 50 瀨 炅 炫 焏 焄 煜 煆 煇 凞 燁 燾 犱 犾 猤 猪 獷",
		"fb 60 玽 珉 珖 珣 珒 琇 珵 琦 琪 琩 琮 瑢 璉 璟 甁 畯",
		"fb 70 皂 皜 皞 皛 皦 益 睆 劯 砡 硎 硤 硺 礰 礼 神",
		"fb 80 祥 禔 福 禛 竑 竧 靖 竫 箞 精 絈 絜 綷 綠 緖 繒",
		"fb 90 罇 羡 羽 茁 荢 荿 菇 菶 葈 蒴 蕓 蕙 蕫 﨟 薰 蘒",
		"fb a0 﨡 蠇 裵 訒 訷 詹 誧 誾 諟 諸 諶 譓 譿 賰 賴 贒",
		"fb b0 赶 﨣 軏 﨤 逸 遧 郞 都 鄕 鄧 釚 釗 釞 釭 釮 釤",
		"fb c0 釥 鈆 鈐 鈊 鈺 鉀 鈼 鉎 鉙 鉑 鈹 鉧 銧 鉷 鉸 鋧",
		"fb d0 鋗 鋙 鋐 﨧 鋕 鋠 鋓 錥 錡 鋻 﨨 錞 鋿 錝 錂 鍰",
		"fb e0 鍗 鎤 鏆 鏞 鏸 鐱 鑅 鑈 閒 隆 﨩 隝 隯 霳 霻 靃",
		"fb f0 靍 靏 靑 靕 顗 顥 飯 飼 餧 館 馞 驎 髙",
		"fc 40 髜 魵 魲 鮏 鮱 鮻 鰀 鵰 鵫 鶴 鸙 黑"
	];

	function find_block( str ){
		var len = kanji_encoding_table.length;

		for( var i = 0; i < len; i++ ){
			var cur = kanji_encoding_table[ i ],
			index = cur.indexOf( str );

			if( index + 1 ){
				return cur;
			}
		}
	}

	function individual_encode( str ){
		var ret = find_block( str );

		if( ret ){
			var split = ret.replace( /\s{2}/g, " " ).split( " " ),
			major = split[ 0 ],
			minor = split[ 1 ],
			index = split.indexOf( str ) - 2;
			return parseInt( "0x" + major + ( parseInt( minor, 16 ) + index ).toString( 16 ), 16 );
		}
	}

	function encode( code ){

		var diff;

		if( code >= 33088 && code <= 40956 ){
			diff = code - 33088;
		} else if( code >= 57408 && code <= 60351 ){
			diff = code - 49472;	
		}

		var base_16 = diff.toString( 16 ),
		len = base_16.length - 2,
		first = base_16.slice( 0, len ).padStart( 2, "0" ),
		second = base_16.slice( len ).padStart( 2, "0" ),
		final = parseInt( first, 16 ) * 192 + parseInt( second, 16 );

		return final.toString( 2 ).padStart( 13, "0" );
	}

	qr_class.prototype.kanji_escape = function( text ){
        var len = text.length,
        str = "";

        for( var i = 0; i < len; i++ ){
        	str += encode( individual_encode( text[ i ] ) );
        }

        return str;
	}
})();